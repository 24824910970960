<template>
	<div>
		<!-- Blocking Modal -->
		<b-modal ref="blocking-modal" hide-header hide-footer modal-class="modal-primary" centered>
			<div class="p-1">
				<div>
					<h4 class="line-height-condensed">Are you sure you want to block the user <span
							class="text-primary">{{ user.emailAddress }}</span></h4>
				</div>
				<validation-observer ref="blockingValidationObserver">
					<b-form-group class="mt-2" v-slot="{ ariaDescribedby }">
						<strong class="font-medium-2">Select the reason</strong>
						<validation-provider #default="{ errors }" rules="required">
							<span class="text-danger d-block" v-if="errors[0]">Selecting a reason is required</span>
							<b-form-radio-group id="radio-options" class="mt-1" v-model="selectedReason"
								:state="errors.length > 0 ? false : null" :aria-describedby="ariaDescribedby"
								name="blocking-options" stacked>
								<b-form-radio value="violation of policies">
									<strong>Violation of Policies</strong>
									<p>The designer is not adhering to the platform's terms of service or community
										guidelines</p>
								</b-form-radio>
								<b-form-radio value="quality issues">
									<strong>Quality Issues</strong>
									<p>The designer consistently delivers work that doesn't meet the platform's quality
										standards</p>
								</b-form-radio>
								<b-form-radio value="unethical conduct">
									<strong>Unethical Conduct</strong>
									<p>The designer exhibits unprofessional behavior, including harassment,
										discrimination, or dishonesty</p>
								</b-form-radio>
								<b-form-radio value="non-compliance">
									<strong>Non-Compliance</strong>
									<p>The designer fails to comply with platform policies, payment processes, or any
										other essential aspects of using the platform</p>
								</b-form-radio>
								<b-form-radio value="other" class="other-option">
									<strong>Other</strong>
									<b-form-textarea :disabled="selectedReason !== 'other'" class="mt-1"
										v-model="otherReason"
										:state="errors.length > 0 ? false : null"></b-form-textarea>
								</b-form-radio>
							</b-form-radio-group>
						</validation-provider>
					</b-form-group>
				</validation-observer>
				<div class="d-flex justify-content-end mt-3">
					<b-button variant="outline-primary" @click="hideBlockingModal">
						Cancel
					</b-button>
					<b-button variant="primary" @click="deactivateUser" class="ml-1">
						block
					</b-button>
				</div>
			</div>
		</b-modal>
		<!-- /Blocking Modal -->

		<!--	unblocking Modal	-->
		<b-modal ref="unblocking-modal" hide-header hide-footer modal-class="modal-primary" centered>
			<div class="p-1">
				<div>
					<h4 class="line-height-condensed">Are you sure you want to unblock the user <span
							class="text-primary">{{ user.emailAddress }}</span></h4>
				</div>
				<div class="d-flex justify-content-end mt-3">
					<b-button variant="outline-primary" @click="$refs['unblocking-modal'].hide()">
						Cancel
					</b-button>
					<b-button variant="primary" @click="activateUser" class="ml-1">
						unblock
					</b-button>
				</div>
			</div>
		</b-modal>
		<!--	/unblocking Modal	-->

		<validation-observer ref="validationObserver">
			<b-card-form>
				<div class="d-flex justify-content-between align-items-center">
					<div class="d-flex align-items-center">
						<h3 class="m-0">Status</h3>
						<span class="ml-2" :class="user.isActive ? 'text-success' : 'text-danger'">
							<feather-icon :icon="getUserStatusIcon" />
							{{ getUserStatusText }}
						</span>
						<b-button variant="link" @click="loginAsUser" class="ml-1">
							Login as {{ user.firstName }}
						</b-button>
					</div>
					<div class="d-flex justify-content-end align-items-center">
						<b-button variant="primary" @click="showBlockingModal" class="ml-1">
							{{ user.isActive ? 'Block' : 'Unblock' }}
						</b-button>
					</div>
				</div>
			</b-card-form>
			<b-card-form>
				<!-- First Name and Last Name -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="First Name">
							<validation-provider #default="{ errors }" rules="required" name="First Name"
								mode="passive">
								<b-form-input v-model="user.firstName" :state="errors.length ? false : null"
									placeholder="First Name" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Last Name">
							<validation-provider #default="{ errors }" rules="required" name="Last Name" mode="passive">
								<b-form-input v-model="user.lastName" :state="errors.length ? false : null"
									placeholder="Last Name" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
				</b-row>

				<!-- Arabic First Name and Last Name -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Arabic First Name">
							<validation-provider v-slot="{ errors }" rules="arabicOnly" name="Arabic First Name" mode="passive">

								<b-form-input v-model="user.firstNameArabic" :state="errors.length ? false : null"
									placeholder="Arabic First Name" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Arabic Last Name">
							<validation-provider v-slot="{ errors }" rules="arabicOnly" name="Arabic Last Name" mode="passive">
								<b-form-input v-model="user.lastNameArabic" :state="errors.length ? false : null"
									placeholder="Arabic Last Name" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>
						</b-form-group>
					</b-col>
				</b-row>


				<!-- Email and Phone -->
				<b-row>
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Email Address">
							<validation-provider #default="{ errors }" rules="required|email" name="Email Address"
								mode="passive">
								<b-form-input type="email" v-model="user.emailAddress"
									:state="errors.length ? false : null" placeholder="Email Address" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>

						</b-form-group>
					</b-col>

					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Phone number">
							<validation-provider #default="{ errors }" rules="required" name="Phone Number"
								mode="passive">
								<b-form-input v-model="user.cellPhone" :state="errors.length ? false : null"
									placeholder="Email Address" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>

						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<!-- Role -->
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Role">
							<validation-provider #default="{ errors }" rules="required" name="Role" mode="passive">
								<v-select v-model="user.role" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="text" :options="roles" placeholder="Select role" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>

						</b-form-group>
					</b-col>
					<!-- Account Type -->
					<b-col md="6" xl="6" class="mb-1">
						<b-form-group label="Account Type">
							<validation-provider #default="{ errors }" rules="required" name="Account Type"
								mode="passive">
								<v-select v-model="user.accountType" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									label="text" :options="accountTypes" placeholder="Select Account Type"
									:clearable="false" />
								<small class="text-danger">{{ errors[0] }}</small>
							</validation-provider>

						</b-form-group>
					</b-col>
				</b-row>

        <b-row>
          <!-- Gender -->
          <b-col md="6" xl="6" class="mb-1">
            <b-form-group label="Gender">
              <validation-provider #default="{ errors }" rules="required" name="Gender"
                                   mode="passive">
                <v-select v-model="user.gender" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          label="text" :options="genderOptions" placeholder="Select Gender"
                          :clearable="false" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>

            </b-form-group>
          </b-col>
        </b-row>

				<b-row>
					<b-col class="text-right">
						<b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="save">
							<span>Save</span>
						</b-button>
					</b-col>
				</b-row>
			</b-card-form>
		</validation-observer>
	</div>
</template>

<script>
import BCardForm from '@/components/b-card-form';
import {
	BFormInput, BRow, BCol, BFormGroup, BButton, BFormRadio, BFormRadioGroup, BFormTextarea
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver,extend } from 'vee-validate';
import {
	required,
	email
} from '@validations';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

 // Extend VeeValidate with a custom rule for Arabic text validation
 extend('arabicOnly', {
  validate(value) {
    // Regular expression to match Arabic characters
    const arabicRegex = /^[\u0600-\u06FF\s]+$/;
    return arabicRegex.test(value);
  },
  message: 'The {_field_} field must contain only Arabic characters.'
});

export default {
	props: ['userId'],

	components: {
		BFormTextarea, BFormRadioGroup, BFormRadio,
		ValidationProvider,
		ValidationObserver,
		BCardForm,
		BFormInput,
		BFormGroup,
		BRow,
		BCol,
		BButton,
		vSelect
	},

	directives: {
		Ripple,
	},

	data() {
		return {
			user: {
				firstName: null,
				lastName: null,
				firstNameArabic: null,
                lastNameArabic : null,
				emailAddress: null,
				cellPhone: null,
				role: null,
				accountType: null,
        gender: null
			},
			roles: [],
			selectedReason: "",
			otherReason: null,
			required,
			email,
			redirectUrl: null,
			accountTypes: ['Individual', 'Office'],
      genderOptions: ['Unknown', 'Male', 'Female']
		}
	},

	methods: {
		async fetchUser() {
			const [user, roles] = await Promise.all([
				this.$http.get(`users/${this.userId}`),
				this.$http.get('lookups/roles')
			]);

			this.user = user.data;
			this.roles = roles.data;
		},

		async save() {
			const isValid = await this.$refs.validationObserver.validate();

			// Validation Failed
			if (!isValid) {
				return this.$toast({
					component: ToastificationContent,
					props: {
						title: 'Check fields validation!',
						icon: 'AlertTriangleIcon',
						variant: 'danger',
					},
				});
			}

			// Validation Passed
			try {
				await this.$http.put('users', this.user);

				this.$toast({
					component: ToastificationContent,
					props: {
						title: 'User has been updated',
						icon: 'CheckCircleIcon',
						variant: 'success',
					},
				});

				this.$router.push({ name: 'UsersIndex' });
			} catch (error) {
			}
		},

		showBlockingModal() {
			if (this.user.isActive) {
				this.$refs["blocking-modal"].show();
			} else {
				this.$refs["unblocking-modal"].show();
			}
		},

		hideBlockingModal() {
			this.$refs['blocking-modal'].hide();
			this.selectedReason = '';
			this.otherReason = null;
		},

		async activateUser() {
			this.$refs["unblocking-modal"].hide();
			const response = await this.$http.post(`users/${this.user.id}/activate`);

			const successMessage = `${this.user.fullName} has been unblocked`;

			this.$toast({
				component: ToastificationContent,
				props: {
					title: successMessage,
					icon: 'CheckCircleIcon',
					variant: 'success',
				},
			});

			this.fetchUser();
		},

		async deactivateUser() {
			const reason = this.selectedReason === 'other' ? this.otherReason : this.selectedReason;

			const valid = this.$refs.blockingValidationObserver.validate().then(async success => {
				if (success) {
					this.selectedReason = '';
					this.otherReason = null;
					this.$refs["blocking-modal"].hide();

					const response = await this.$http.post("users/deactivate", {
						id: this.user.id,
						reason: reason
					});

					const successMessage = `${this.user.fullName} has been blocked`;

					this.$toast({
						component: ToastificationContent,
						props: {
							title: successMessage,
							icon: 'CheckCircleIcon',
							variant: 'success',
						},
					});

					this.fetchUser();

				} else {
					this.$toast({
						component: ToastificationContent,
						props: {
							title: 'Please select a reason for blocking this user!',
							icon: 'BellIcon',
							variant: 'danger',
						},
					})
				}
				return success
			})
			return valid
		},

		async loginAsUser() {
			const response = await this.$http.post("AdminClientAccessTokenApi/generate", {
				clientId: this.userId
			});
			window.open(response.data, '_blank');
		}
	},

	computed: {
		getUserStatusText() {
			return this.user.isActive ? 'Active' : 'Blocked';
		},

		getUserStatusIcon() {
			return this.user.isActive ? 'CheckCircleIcon' : 'MinusCircleIcon';
		},
	},

	async created() {
		try {
			await this.fetchUser();
		} catch (error) {
		}
	}
}
</script>

<style lang="scss">
.other-option label {
	width: 100%;
}
</style>